import React, { useEffect, useState, useRef } from 'react';
import SupportChat from '../components/SupportChat/SupportChat';
import '../assets/scss/OurTeam/_ourTeam.scss';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../components/PageLoader/PageLoader';
import { motion } from "framer-motion";
import request from "../components/Request/request";
import {Helmet} from "react-helmet";


const OurTeam = () => {

    const isMounted = useRef(true);
    const navigate = useNavigate();
    const [servicesData, setServicestData] = useState(null);
    const [isLoadSuccess, setIsLoadSuccess] = useState(false);
    const [servicePage, setServicePage] = useState(null);
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {
        request(`https://q2dcjb51.a2hosted.com/api/page/our-team`)
                .then((data) => {
                    setMetaData(data.data);
                })
                .catch(error => {
                    console.log(error);
                })
    }, [])

    useEffect(() => {
        const path = window.location.href;
        const parts = path.split("/");
        let desiredPart = parts.slice(parts.indexOf("team") + 1).join("/");

        const currentId = parseInt(desiredPart.match(/\d+/), 10);
        let element;
        setServicePage(Math.ceil(currentId / 4));
        element = document.getElementById(desiredPart);
        if (element) {
            if (desiredPart === 'all') {
                document.body.scrollIntoView({ behavior: 'smooth' });
            } else if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: "center" });
            }
        } else {
            setIsLoadSuccess(false);
            if (isMounted.current) {
                let servicesLink;
                servicesLink = `https://q2dcjb51.a2hosted.com/api/team/1`
                request(servicesLink)
                    .then((services) => {
                      console.log(services.data);
                        setServicestData(services.data);
                        setTimeout(() => {
                            element = document.getElementById(desiredPart);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: "center" });
                            }
                        }, 600);
                        setTimeout(() => {
                            setIsLoadSuccess(true);
                        }, 500);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }, [navigate, servicePage])

    if (!isLoadSuccess) {
        return <PageLoader />
    }

    return (
      <>

      <Helmet>
          <meta charSet="utf-8" />
          <title>{metaData.meta_title}</title>
          <meta
            name="title"
            content={metaData.meta_title}
          />
          <meta
            name="description"
            content={metaData.meta_description}
          />
          <meta
            name="keywords"
            content={metaData.meta_keywords}
          />
      </Helmet>
        <motion.div className="team_wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="custom_container">
                <div className="section_title center_mode"><h1>Our Team</h1></div>
            </div>
            <div className="team_list page_section ">
                {servicesData &&
                    servicesData.data.map((team) => (
                        <div key={team.id} className="team_block second_bg" id={team.position.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
                            <div className="custom_container">
                                <div className="team_img">
                                  <img className="image_block"  src={team.image} alt="team" />

                                </div>
                                <div className="team_info">
                                    <h2 className="section_title service_title">
                                        {team.position}
                                    </h2>
                                    <div className="section_description"    dangerouslySetInnerHTML={{__html: team.description}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <SupportChat />
        </motion.div>
        </>

    )
}

export default OurTeam;
