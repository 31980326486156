
import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/ErrorPage/_errorPage.scss';
import img from '../../src/assets/img/404.jpg';

const NotFound = () => {
  return (
    <div className="error_wrapper section_title center_mode">
      <h1>OOOPS!</h1>
      <p className="not-found">Page not found</p>
      <img src={img} alt="error" />
      <div className="bttom_inline">
      <Link to='/' className="btn">Go To Homepage</Link>
      </div>
    </div>
  );
}

export default NotFound;
