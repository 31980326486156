import React, { useState, useEffect } from 'react';
import SupportChat from '../components/SupportChat/SupportChat';
import '../assets/scss/Blog/_blog.scss';
import { motion } from "framer-motion";
import request from '../components/Request/request';
import PageLoader from '../components/PageLoader/PageLoader';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import {Helmet} from "react-helmet";

const Blog = () => {

    const [blogData, setBlogData] = useState({});
    const [blogPage, setBlogPage] = useState({});

    const [pageinationData, setPageinationData] = useState({});
    const [isLoadSuccess, setLoadSuccess] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async (page = 1) => {
      request(`https://q2dcjb51.a2hosted.com/api/blog/${page}`)
      .then((blogData) => {
        console.log(blogData);
          setBlogData(blogData.data);
          setBlogPage(blogData.seo);
          setPageinationData(blogData.pagination);
          setTimeout(() => {
              setLoadSuccess(true)
          }, 500);
      })
      .catch(error => {
          console.log(error);
      })
    };


    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setLoadSuccess(false);
        fetchData(pageNumber);
    };

    if (!isLoadSuccess) {
        return <PageLoader />
    }
    function TruncatedHtml({ content, maxLength }) {
      if (content.length <= maxLength) {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
         } else {
           const truncatedContent = content.substring(0, maxLength);
           const lastIndex = Math.min(truncatedContent.length, truncatedContent.lastIndexOf('<'));
           const html = truncatedContent.substring(0, lastIndex);

      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }
  }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{blogPage.meta_title}</title>
                <meta
                  name="title"
                  content={blogPage.meta_title}
                />
                <meta
                  name="description"
                  content={blogPage.meta_description}
                />
                <meta
                  name="keywords"
                  content={blogPage.meta_keywords}
                />
                <link rel="canonical" href="/blog" />
            </Helmet>
            <motion.div className="blog_wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="custom_container">
                    <div className="section_title center_mode"><h1>Blog</h1></div>
                    <div className="blog_list" id="blog_list">


                        {blogData &&
                            blogData.data.map((blog, index) => (

                                <div key={index} className="blog_block">
                                    <div className="image_block">
                                        <img src={blog.image} alt="blogImg" />
                                    </div>
                                    <div className="blog_info">
                                        <h2>
                                        <Link to={`/blog/${blog.slug}`} className="blog_title">
                                            {blog.title}
                                        </Link>
                                        </h2>
                                        <div className="blog_description">
                                        <TruncatedHtml content={blog.description} maxLength={1000} />
                                        <Link to={`/blog/${blog.slug}`} className="seeMore">
                                            See more
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="pagination_section">
                            <Pagination >
                                <Pagination.Prev onClick={(e) => {
                                    handlePageChange(e, page - 1)
                                }}
                                    disabled={page === 1 ? true : false}
                                />
                                {[...Array(Math.floor(pageinationData.total))].map((_, i) => (
                                    <Pagination.Item
                                        active={page === i + 1 ? true : false}
                                        key={i + 1}
                                        onClick={(e) => handlePageChange(e, i + 1)}
                                    >{i + 1}</Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={(e) => {
                                        handlePageChange(e, page + 1)
                                    }}

                                    disabled={page === pageinationData.total ? true : false}
                                />
                            </Pagination>
                        </div>

                    </div>
                </div>
                <SupportChat />
            </motion.div>
        </>
    )
}

export default Blog;
