import React, { useEffect, useState, useRef } from 'react';
import SupportChat from '../components/SupportChat/SupportChat';
import '../assets/scss/Services/_services.scss';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import request from "../components/Request/request";
import PageLoader from '../components/PageLoader/PageLoader';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const Services = () => {

    const moreBtnRef = useRef(null);
    const navigate = useNavigate();

    const [servicesData, setServicestData] = useState(null);
    const [servicesPage, setServicestPage] = useState(null);
    const [isLoadSuccess, setIsLoadSuccess] = useState(false);
    const [descriptionPoint, setdescriptionPoint] = useState(370);

    const handleClickSchedul = (e) => {
        e.preventDefault();
        navigate('/contactUs', { replace: true });
    }

    const handleDescDropdown = (e, serviceId) => {
        e.preventDefault();
        e.target.classList.add('hide')
        const descriptionBlock = document.getElementById(serviceId);
        descriptionBlock.classList.add('active');
    }

    useEffect(() => {
        setIsLoadSuccess(false);
            let servicesLink = `https://q2dcjb51.a2hosted.com/api/services`;
            request(servicesLink)
                .then((services) => {
                    setServicestData(services.data);
                    setServicestPage(services.seo)
                    setTimeout(() => {
                        document.body.scrollIntoView({ behavior: 'smooth' });
                    }, 600);
                    setTimeout(() => {
                        setIsLoadSuccess(true);
                    }, 500);
                })
                .catch(error => {
                    console.log(error);
                })



    }, [])

    if (!isLoadSuccess) {
        return <PageLoader />
    }

    return (
      <>

      <Helmet>
          <meta charSet="utf-8" />
          <title>{servicesPage.meta_title}</title>
          <meta
            name="title"
            content={servicesPage.meta_title}
          />
          <meta
            name="description"
            content={servicesPage.meta_description}
          />
          <meta
            name="keywords"
            content={servicesPage.meta_keywords}
          />
      </Helmet>
        <motion.div className="services_wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="custom_container">
                <div className="section_title center_mode"><h1>Our Services</h1></div>
            </div>
            <div className="services_list page_section ">
                {servicesData && servicesData.data.map((service) => (
                        <div key={service.id} id={`service${service.id}`} className="goals_section service_block inlineImg_section second_bg"  >
                            <div className="inlineImg_container">
                                <div className="image_block absoluite_image" style={{ backgroundImage: `url(${service.image.replace(/\s/g, '')})` }}></div>
                                <div className="goal_info page_section">
                                    <div className="info_inner">
                                        <Link to={`/service/${service.slug}`} className="seeMore">
                                            <h2 className="section_title service_title">
                                                {service.title}
                                            </h2>
                                            <div className="description_block" id={service.id} >
                                                <div className="section_description"       dangerouslySetInnerHTML={{__html: service.description}}>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="buttons_line">
                                            <Link to={`/service/${service.slug}`} className="seeMore">
                                                See more
                                            </Link>
                                            <a href="/#" onClick={(e) => handleClickSchedul(e)} className="site_btn">Schedule</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <SupportChat />
        </motion.div>
        </>
    )
}

export default Services;
