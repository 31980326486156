import React, { useState, useEffect, useRef } from 'react';
import SupportChat from '../components/SupportChat/SupportChat';
import '../assets/scss/Physicians/_physicians.scss';
import serviveInner1 from '../assets/img/joinTeam.png';
import { useForm, Controller } from "react-hook-form";
import { motion } from "framer-motion";
import request from '../components/Request/request';
import PageLoader from '../components/PageLoader/PageLoader';
import {Helmet} from "react-helmet";

const Physicians = () => {

    const success = useRef(null);
    const [dataSend, setDataSend] = useState(false);
    const isMounted = useRef(true);
    const [referalData, setReferalData] = useState({})
    const [isLoadSuccess, setLoadSuccess] = useState(false);

    const { register, control, handleSubmit: handleSubmitForm1, formState: { errors } } = useForm({
        shouldFocusError: false,
    });

    useEffect(() => {
        if (isMounted.current) {
            request(`https://q2dcjb51.a2hosted.com/api/page/referral`)
                .then((referalData) => {
                    setReferalData(referalData.data);
                    setTimeout(() => {
                        setLoadSuccess(true)
                    }, 500);
                })
                .catch(error => {
                    console.log(error);
                })
        }
        // console.log(referalData);
        return () => {
            isMounted.current = false;
        };
    }, [referalData])

    const onSubmit = async (data) => {
        setDataSend(true);
        try {
            const formData = new FormData();
            formData.append('physician_name', data.physician_name);
            formData.append('phone', data.phone);
            formData.append('agree', data.agree);
            formData.append('name', data.name);
            formData.append('last_name', data.last_name);
            formData.append('contact_person', data.contact_person);
            formData.append('contact_person_phone', data.contact_person_phone);
            formData.append('address', data.address);
            formData.append('city', data.city);
            formData.append('birth', data.birth);
            formData.append('diagnosis', data.diagnosis);
            formData.append('questions', data.questions);

            const response = await fetch('https://q2dcjb51.a2hosted.com/api/new-referral', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {

                success.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setTimeout(() => {
                    setDataSend(false);
                }, 12000);
            } else {
                console.error('Error submitting form:', response.statusText);
            }

        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    if (!isLoadSuccess) {
        return <PageLoader />
    }
    return (
      <>

      <Helmet>
          <meta charSet="utf-8" />
          <title>{referalData.meta_title}</title>
          <meta
            name="title"
            content={referalData.meta_title}
          />
          <meta
            name="description"
            content={referalData.meta_description}
          />
          <meta
            name="keywords"
            content={referalData.meta_keywords}
          />
      </Helmet>
        <motion.div className="physicians_wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="custom_container">
                <div className="section_title center_mode"><h1>Physicians Referral</h1></div>
            </div>
            <div className="cover_Image" style={{ backgroundImage: `url(${referalData.banner_image})` }}>
                <div className="custom_container">
                    <div className="cover_title">
                    {referalData.banner_title}
                    </div>
                </div>
            </div>
            <div className="custom_container">
                <h2 className="section_description referal_desc center_mode"     dangerouslySetInnerHTML={{__html: referalData.description}}>
                </h2>
            </div>
            <div className="form_section" style={{ background: `url(${serviveInner1})` }}>
                <div className={dataSend ? `success_message view` : `success_message`} ref={success}>Success ! ✔</div>
                <div className="custom_container">
                    <div className="form_container">
                        <div className="form_inner">
                            <form onSubmit={handleSubmitForm1(onSubmit)}>
                                <div className='block_label_blue'>Doctor Information:</div>
                                <div className={errors?.physician_name?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Physician Name*</div>
                                    <input placeholder="Physician Name" className="form-control" name="physician_name" {...register("physician_name", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.phone?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Phone*</div>
                                    <input placeholder="Phone" className="form-control" name="phone" {...register("phone", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className="bottom_description">
                                    Enter your request details. A member of our support team will respond to you as soon as possible.
                                </div>
                                <div className='block_label_blue'>Patient Information:</div>
                                <div className={errors?.name?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>First Name*</div>
                                    <input placeholder="First Name" className="form-control" name="name" {...register("name", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.last_name?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Last Name*</div>
                                    <input placeholder="Last Name" className="form-control" name="last_name" {...register("last_name", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.contact_person?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Contact Person*</div>
                                    <input placeholder="Contact Person" className="form-control" name="contact_person" {...register("contact_person", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.contact_person_phone?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Contact Person Phone*</div>
                                    <input placeholder="Contact Person Phone" className="form-control" name="contact_person_phone" {...register("contact_person_phone", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.address?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Address*</div>
                                    <input placeholder="Address" className="form-control" name="address" {...register("address", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.city?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>City, State, Zip*</div>
                                    <input placeholder="City, State, Zip" className="form-control" name="city" {...register("city", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.birth?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Date of Birth*</div>
                                    <input placeholder="Date of Birth" className="form-control" name="birth" {...register("birth", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.diagnosis?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Diagnosis</div>
                                    <input placeholder="Diagnosis" className="form-control" name="diagnosis" {...register("diagnosis", { required: false })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.questions?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Questions or Comments</div>
                                    <input placeholder="Questions or Comments" className="form-control" name="questions" {...register("questions", { required: false })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <button type='submit' className="site_btn sign-btn" disabled={dataSend}>Send</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <SupportChat />
        </motion.div>
      </>
    )
}

export default Physicians;
