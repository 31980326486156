import React, { useEffect, useState, useRef } from 'react';
import SupportChat from '../components/SupportChat/SupportChat';
import '../assets/scss/Services/_services.scss';
import { useForm } from "react-hook-form";
import '../assets/scss/ContactUs/_contactUs.scss';
import { motion } from "framer-motion";
import request from "../components/Request/request";
import PageLoader from '../components/PageLoader/PageLoader';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';

const ServicInner = () => {
    const navigate = useNavigate();

    const isMounted = useRef(true);
    const success = useRef(null);
    const [servicesData, setServicestData] = useState(null);
    const [isLoadSuccess, setIsLoadSuccess] = useState(true);
    const [contactData, setContactData] = useState({})
    const [dataSend, setDataSend] = useState(false);
    const { register, handleSubmit: handleSubmitForm1, formState: { errors } } = useForm({
        shouldFocusError: false,
    });
    const [serviceInnerData, setServiceInnerData] = useState({});

    useEffect(() => {

        const path = window.location.href;
        const parts = path.split("/");
        let ServiceSlug = parts.slice(parts.indexOf("service") + 1).join("/");
        request(`https://q2dcjb51.a2hosted.com/api/service/${ServiceSlug}`)
            .then((data) => {
              if (!data.data) {
                navigate('/not-found', { replace: true });
              }
                setServiceInnerData(data.data);
                
                setTimeout(() => {
                    setIsLoadSuccess(true)
                }, 500);
            })
            .catch(error => {
              navigate('/not-found', { replace: true });

            })
    },[])

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('email', data.email);
            formData.append('name', data.name);
            formData.append('surname', data.surname);
            formData.append('description', data.description);
            // formData.append('pdfFile', data.pdfFile);

            const response = await fetch('https://hospis.dev.itfabers.com/api/new-contact', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setDataSend(true);
                // success.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                window.scrollTo(0, document.getElementById('success').offsetTop - 200);
                setTimeout(() => {
                    setDataSend(false);
                }, 12000);
            } else {
                console.error('Error submitting form:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    // useEffect(() => {
    //     const path = window.location.href;
    //     const parts = path.split("/");
    //     let desiredPart = parts.slice(parts.indexOf("services") + 1).join("/");
    //     const currentId = parseInt(desiredPart.match(/\d+/), 10);
    //     let element;
    //     if (isMounted.current) {

    //         request(`https://hospis.dev.itfabers.com/api/services/${currentId}`)
    //             .then((services) => {
    //                 setServicestData(services.data);
    //                 setTimeout(() => {
    //                     setIsLoadSuccess(true);
    //                 }, 500);
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             })
    //     }
    // }, [])

    if (!isLoadSuccess) {
        return <PageLoader />
    }

    return (
      <>

      <Helmet>
          <meta charSet="utf-8" />
          <title>{serviceInnerData.meta_title}</title>
          <meta
            name="title"
            content={serviceInnerData.meta_title}
          />
          <meta
            name="description"
            content={serviceInnerData.meta_description}
          />
          <meta
            name="keywords"
            content={serviceInnerData.meta_keywords}
          />
      </Helmet>
        <motion.div className="services_wrapper service_inner"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="custom_container">
                <div className="section_title center_mode"><h1>{serviceInnerData.title}</h1></div>
            </div>
            <div className="services_list page_section ">
                <div className="goals_section service_block inlineImg_section second_bg"  >
                    <div className="inlineImg_container">
                        <div className="image_block absoluite_image" style={{ backgroundImage: `url(${serviceInnerData.image})` }}></div>
                        <div className="goal_info page_section">
                            <div className="info_inner">
                                <div className="description_block"  >
                                    <h2 className="section_description"  dangerouslySetInnerHTML={{__html: serviceInnerData.description}}>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form_section" style={{ background: `url()` }}  ref={success}>
                <div className={dataSend ? `success_message view` : `success_message`}  id="success">Success ! ✔</div>
                <div className="custom_container">
                    <div className="form_container">
                        <div className="form_inner">
                            <form onSubmit={handleSubmitForm1(onSubmit)}>
                                <div className={errors?.name?.type === "required" ? "form-block  has-error" : "form-block"}  >
                                    <div className='block_label'>Full Name*</div>
                                    <input placeholder="Full Name" className="form-control" name="name" {...register("name", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className={errors?.email?.type === "required" || errors?.email?.type === "pattern" ? "email_inline form-block has-error" : "email_inline form-block"}  >
                                    <div className='block_label'>Email*</div>
                                    <input placeholder="Email" className="form-control" name="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                                    {errors?.email?.type === "pattern" ? <p className="error-info eemail-info" >invalid email</p> :
                                        <p className="error-info" >This field is required</p>}
                                </div>

                                <div className={errors?.description?.type === "required" ? "form-block  has-error" : "form-block"} >
                                    <div className='block_label'>How may New Hope Hospice Care assist you?*</div>
                                    <input placeholder="How may New Hope Hospice Care assist you?" className="form-control"  id="messageInput" name="description" {...register("description", { required: true })} />
                                    <p className="error-info" >This field is required</p>
                                </div>
                                <div className="bttom_inline">
                                    <button type='submit' className="site_btn sign-btn" disabled={dataSend}>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <SupportChat />
        </motion.div>
        </>
    )
}

export default ServicInner;
