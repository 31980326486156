import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import '../assets/scss/HomePage/_homePage.scss';
import SupportChat from '../components/SupportChat/SupportChat';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MapContainer from '../components/MapContainer/MapContainer';
import PageLoader from '../components/PageLoader/PageLoader';
import request from "../components/Request/request";
import {Helmet} from "react-helmet";
import img from '../../src/assets/img/freeConsult.svg';

const HomePage = () => {

    const [isLoadSuccess, setIsLoadSuccess] = useState(false);
    const [homeData, setHomeData] = useState({});

    useEffect(() => {
        request(`https://q2dcjb51.a2hosted.com/api/page/home`)
            .then((home) => {
              console.log(home);
                setHomeData(home.data);
                setTimeout(() => {
                    setIsLoadSuccess(true);
                }, 500);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    if (!isLoadSuccess) {
        return <PageLoader />
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{homeData.meta_title}</title>
                <meta
                  name="title"
                  content={homeData.meta_title}
                />
                <meta
                  name="description"
                  content={homeData.meta_description}
                />
                <meta
                  name="keywords"
                  content={homeData.meta_keywords}
                />
                <link rel="canonical" href="/" />
            </Helmet>
            <motion.div className="homepage_wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="hero_section">
                    <img src={homeData.banner_image} alt='heroImg' />

                    <div className="custom_container banner_custom">
                        <div className="banner_info">
                            <p className='info_desc'><h1>{homeData.banner_title}</h1></p>
                            {
                            // <Link to='aboutUs/' className="baner_btn site_btn">{homeData.Banner.ButtonText}</Link>
                          }
                        </div>
                    </div>
                </div>
                <div>
                    <h3>Free Consultations Available</h3>
                    <div>
                        <p>Insurance Accepted</p>
                        <p>HMO & Private Pay Accepted</p>
                        <p>Trusted by Families</p>
                        <p>Compassionate End-of-Life Care</p>
                        <p>Dignity and Respect Guaranteed</p>
                    </div>
                    <div>
                        <a href="tel:+1(888) 965-9595" class="site_btn call_btn" style={{width: '220px'}}>+1(888) 965-9595</a>
                    </div>
                    <div>
                        <p>We Accept Medicare & Medi-Cal</p>
                        <p>Experienced hospice Team</p>
                        <p>Pain Management Experts</p>
                        <p>Expert Palliative Care Team</p>
                        <p>Immediate Hospice Care Available</p>
                    </div>
                    <img src={img}/>
                </div>
                <div className="about_section second_bg page_section">

                {
                  homeData.sections.map((section , index) =>(
                  section.name == "about" ?
                  <>
                  <div className="custom_container">
                      <div className="section_title center_mode"><h2>{section.title}</h2></div>
                      <div className="section_description center_mode"    dangerouslySetInnerHTML={{__html: section.description}}></div>
                  </div>
                  </>:<></>
                ))
                }
                </div>
                <div className="services_section page_section">
                {
                  homeData.sections.map((section , index) =>(
                  section.name == "services" ?
                  <>
                  <div className="custom_container">
                      <div className="section_title center_mode"><h2>{section.title}</h2></div>
                      <div className="section_description center_mode"    dangerouslySetInnerHTML={{__html: section.description}}></div>
                  </div>
                  </>:<></>
                ))
                }

                    <div className="services_list">
                        {homeData.services.map((service , index) =>(
                            <div  key={index} className="serbvice_block">
                                <Link to={`service/${service.slug}`} >
                                    <img src={service.image} alt='serviceImg' />
                                    <h3 className="service_title">
                                        {service.title}
                                    </h3>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="team_section page_section">

                    {
                      homeData.sections.map((section , index) =>(
                      section.name == "team" ?
                      <>
                      <div className="custom_container">
                          <div className="section_title center_mode"><h2>{section.title}</h2></div>
                          <div className="section_description center_mode"    dangerouslySetInnerHTML={{__html: section.description}}></div>
                      </div>
                      </>:<></>
                    ))
                    }
                    <div className="team_container second_bg page_section">
                        <div className="custom_container">
                            <div className="team_list">
                                {homeData.team.map((members, index) => (
                                    <div key={index} className="team_block">
                                        <Link to={`/team/${members.position.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`}>
                                            <span className='team_img'>
                                                <img src={members.image} alt='teamImg' />
                                            </span>
                                            <h3 className="team_title">
                                                {members.position}
                                            </h3>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {
                // <div className="goals_section  inlineImg_section">
                //     <div className="inlineImg_container">
                //         <div className="image_block absoluite_image">
                //             <img src={homeData.DifferentInfo.Image} alt='goalImg' />
                //         </div>
                //         <div className="goal_info page_section">
                //             <div className="info_inner">
                //                 <div className="section_title ">
                //                     {homeData.DifferentInfo.Title}
                //                 </div>
                //                 <div className="section_description">
                //                     {homeData.DifferentInfo.Description}
                //                 </div>
                //                 <div className="goals_list">
                //                     {homeData.DifferentInfo.Icons.map((icon, index) => (
                //                         <div key={index} className="goal_block">
                //                             <div className="goal_img">
                //                                 <img src={icon.Icon} alt="goalImg" />
                //                             </div>
                //                             <div className="goal_title">
                //                                 {icon.Title}
                //                             </div>
                //                         </div>
                //                     ))}
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
              }
                <div className="certificates_section page_section">
                    <div className="custom_container">
                        <div className="section_title center_mode"><h2>Our Certifications</h2></div>
                        <div className="certificate_list">
                            {homeData.certificates.length>0 && homeData.certificates.map((certificate, index) => (
                                <div key={index} className="certificate_block">
                                    <img src={certificate.image} alt="certificateIMg" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="coverBottom_section">
                    <div className="cover_image"><img src={homeData.image.replace(/\s/g, '')} alt='coverImg' /></div>
                </div>

                <div className="location_section">
                    <MapContainer array={[{"Id": 1,
                      "Country": "USA",
                      "Location": {
                        "lat": 34.18646559822603,
                        "lng": -118.40441200426372
                      }}]} isAdding={true} error={false} />
                </div>

                <SupportChat />
            </motion.div>
        </>
    )
}

export default HomePage;
